<template>
  <div class="banner">
    <img :src="require('@/assets' + bannerList.src)" alt="">
    <div class="banner-tips" :style="{height: bannerList.height}">
      <div class="tips-item" v-for="(item, index) in bannerList.list" :key="index">
        <svg-icon :icon-class="item.icon"></svg-icon>
        <div>
          <p>{{item.title}}</p>
          <p>{{item.content}}</p>
        </div>
          
      </div>
      
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      bannerList: {
        type: Object,
        required: true
      }
    },
  }
</script>

<style lang="scss" scoped>
  .banner {
    position: relative;
    > img {
        width: 100%;
    }
    .banner-tips {
      width: 3.43rem;
      background-color: #FFFFFF;
      border-radius: .08rem;
      -webkit-box-shadow: 0 .08rem .2rem rgba(0, 0, 0, 0.04);
      -moz-box-shadow: 0 .08rem .2rem rgba(0, 0, 0, 0.04);
      box-shadow: 0 .08rem .2rem rgba(0, 0, 0, 0.04);
      position: absolute;
      left: .16rem;
      top: 1.8rem;
      padding: .24rem .16rem .24rem .12rem; 
      .tips-item {
        display: flex;
        margin-bottom: .24rem;
        .svg-icon {
          width: .4rem;
          height: .4rem;
          margin-right: .08rem;
        }
        > div {
          width: 2.67rem;
          > p {
            &:nth-child(1) {
              font-size: .15rem;
              font-weight: 500;
              line-height: .22rem;
              color: rgba(0, 0, 0, 0.85);
            }
            &:nth-child(2) {
              font-size: .13rem;
              font-weight: 400;
              line-height: .234rem;
              color: rgba(0, 0, 0, 0.6);
            }
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
</style>