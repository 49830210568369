<template>
  <div class="civil-container">
    <banner :banner-list="bannerList" />
    <div class="call">
      <div class="call-content">
        <div class="call-title">呼叫中心</div>
        <p>利用语音网关、话务设备搭建基础呼叫系统，集成养老服务热线，保证市民只要拨打养老服务热线，足不出户就能享受到家政服务、医疗救助、配送服务、餐饮服务等方便快捷、细致周到的健康养老服务。须包括呼叫中心话务系统、服务调度管理系统、养老服务政策和知识库管理系统等系统，实现对服务的全流程闭环管理。</p>
      </div>
      <div class="call-tips">
        <div class="call-item">
          <svg-icon icon-class="YYWG"></svg-icon>
          <p>语音网关</p>
        </div>
        <div class="call-item">
          <svg-icon icon-class="GDXT"></svg-icon>
          <p>工单系统</p>
        </div>
        <div class="call-item">
          <svg-icon icon-class="DDGL"></svg-icon>
          <p>调度管理</p>
        </div>
        <div class="call-item">
          <svg-icon icon-class="ZSK"></svg-icon>
          <p>知识库</p>
        </div>
      </div>
    </div>
    <div class="elderly-university">
      <div class="university-title">线上老人大学</div>
      <div class="university-item">
        <svg-icon icon-class="Label"></svg-icon>
        <p>日程/课室管理</p>
        <p>满足多个学习分课室、不同日程的管理需要，兼顾日程提示、日程时间、日程内容等功能，确保活动的有序进行。</p>
      </div>
      <div class="university-item">
        <svg-icon icon-class="Label"></svg-icon>
        <p>参会报名</p>
        <p>支持个人及团队在线报名参会，操作简单易上手，提升用户操作体验。</p>
      </div>
      <div class="university-item">
        <svg-icon icon-class="Label"></svg-icon>
        <p>短信群发推广</p>
        <p>课程可配置线上直播、点播，线上线下同时进行，直播结束后实时生成回放视频。</p>
      </div>
      <div class="university-item">
        <svg-icon icon-class="Label"></svg-icon>
        <p>视频观看</p>
        <p>课程可配置线上直播、点播，线上线下同时进行，直播结束后实时生成回放视频。</p>
      </div>
      <div class="university-item">
        <svg-icon icon-class="Label"></svg-icon>
        <p>资料下载</p>
        <p>课程活动相关资料信息可上传到课室中，支持上传多种文件格式；展示课程页面便于参课人员下载查阅。</p>
      </div>
    </div>
  </div>
</template>

<script>
  import Banner from '@/components/banner/Banner.vue'
  export default {
    data() {
      return {
        bannerList: {
          src: '/global/civil/banner.png',
          list: [
            {
              icon: 'keshihua',
              title: '中央控制平台(数据可视化监控)',
              content: '辖区内养老状况，包括居家养老数据、机构养老数据、养老服务数据、老人服务位置数据、养老综合数据等全面可视化监控。'
            },
            {
              icon: 'butie',
              title: '补贴发放管理监管',
              content: '补贴标准设置、发放、申请、审核，以及补贴记录的查询汇总；全流程数据自动上报及监督，一目了然。'
            },
            {
              icon: 'servic',
              title: '服务管理监管',
              content: '养老服务商数据、服务人员数据、服务工单数据等监控，保障老人服务需求匹配、落实。'
            }
          ]
        },
      };
    },
    components: {
      Banner
    }
  }
</script>

<style lang="scss" scoped>
  .civil-container {
    .call {
      margin-top: 2.52rem;
      height: 4rem;
      background: url('~@/assets/global/civil/bg.png') no-repeat center center;
      background-size:100% 100%;
      position: relative;
      .call-content {
        background-color: #FFFFFF;
        height: 3.17rem;
        width: 2.4rem;
        padding: .24rem .16rem;
        -webkit-box-shadow: 0 -.02rem .1rem rgba(0, 0, 0, 0.04);
        -moz-box-shadow: 0 -.02rem .1rem rgba(0, 0, 0, 0.04);
        box-shadow: 0 -.02rem .1rem rgba(0, 0, 0, 0.04);
        position: absolute;
        left: .16rem;
        top: -.62rem;
        .call-title {
          font-size: .18rem;
          font-weight: 500;
          line-height: .21rem;
          color: rgba(0, 0, 0, 0.85);
          margin-bottom: .1rem;
        }
        > p {
          font-size: .13rem;
          font-weight: 400;
          line-height: .23rem;
          color: rgba(0, 0, 0, 0.6);
        }
      }
      .call-tips {
        display: flex;
        justify-content: space-around;
        height: 100%;
        align-items: flex-end;
        padding-bottom: .265rem;
        .call-item {
          text-align: center;
          .svg-icon {
            font-size: .32rem;
            margin-bottom: .04rem;
          }
          > p {
            font-size: .13rem;
            font-weight: 500;
            color: #FFFFFF;
            line-height: .15rem;
          }
        }
      }
    }
    .elderly-university {
      margin-top: .48rem;
      margin-bottom: .36rem;
      padding: 0 .16rem;
      .university-title {
        font-size: .18rem;
        font-weight: 500;
        line-height: .26rem;
        color: rgba(0, 0, 0, 0.85);
        margin-bottom: .28rem;
        text-align: center;
      }
      .university-item {
        height: 1.49rem;
        background-color: rgba(0, 173, 246, 0.04);
        border-radius: .08rem;
        margin-bottom: .12rem;
        padding: .24rem .16rem;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .svg-icon {
          position: absolute;
          width: .17rem;
          height: .21rem;
          top: 0;
          right: .16rem;
        }
        > p {
          &:nth-child(2) {
            font-size: .15rem;
            font-weight: 500;
            line-height: .22rem;
            color: rgba(0, 0, 0, 0.85);
          }
          &:nth-child(3) {
            font-size: .13rem;
            font-weight: 400;
            line-height: .234rem;
            color: rgba(0, 0, 0, 0.6);
          }
        }
      }
    }
  }
</style>